import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Thumbs, Navigation, Pagination } from 'swiper';
import 'swiper/css/thumbs';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  zIndexLayers,
} from '../styles';
import { Slider } from './ui';
import { isOnSale } from '../utils';

const StyledProductGallery = styled.section``;

const StyledInner = styled.div`
  ${minBreakpointQuery.large`
    position: relative;
    padding-right: 90px;
  `}
`;

const StyledMainImages = styled.div`
  position: relative;
`;

const StyledMainImage = styled.div`
  cursor: zoom-in;
`;

const StyledLabel = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 15px;
  color: ${standardColours.white};
  ${fontSize(10)};
  text-align: center;
  text-transform: uppercase;
  background-color: ${brandColours.quinary};
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: left top;

  ${minBreakpointQuery.small`
    ${fontSize(11)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(12)};
  `}
`;

// moved up for active state
const StyledNavImage = styled(GatsbyImage)`
  position: relative;
  aspect-ratio: 1/1;
  border: 1px solid ${standardColours.grey};
  cursor: pointer;
`;

const StyledNavImages = styled(Slider)`
  ${maxBreakpointQuery.large`
    margin-top: 15px;
  `}

  ${minBreakpointQuery.large`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  `}

  .swiper-slide-thumb-active {
    ${StyledNavImage} {
      border-color: ${brandColours.tertiary};
    }
  }
`;

const StyledPopupImages = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: ${standardColours.transparentBlack(0.5)};
  cursor: pointer;
  z-index: ${zIndexLayers.fifth};
`;

const StyledPopupImagesSlider = styled(Slider)`
  max-width: 740px;
  --swiper-navigation-color: ${brandColours.tertiary};
  --swiper-pagination-color: ${brandColours.tertiary};
  cursor: initial;
`;

const StyledPopupImage = styled(GatsbyImage)``;

const ProductGallery = ({
  images: { mainImages, navImages, popupImages },
  title,
  variants,
  ...props
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const mainSliderOptions = {
    spaceBetween: 10,
    thumbs: { swiper: thumbsSwiper },
    modules: [Thumbs],
  };

  const navSliderOptions = {
    onSwiper: setThumbsSwiper,
    spaceBetween: 10,
    slidesPerView: 5,
    modules: [Thumbs],
    breakpoints: {
      [breakpointSizes.tiny]: {
        slidesPerView: 6,
        direction: 'horizontal',
      },
      [breakpointSizes.tsmall]: {
        slidesPerView: 7,
        direction: 'horizontal',
      },
      [breakpointSizes.small]: {
        slidesPerView: 4,
        direction: 'horizontal',
      },
      [breakpointSizes.smedium]: {
        slidesPerView: 5,
        direction: 'horizontal',
      },
      [breakpointSizes.medium]: {
        slidesPerView: 6,
        direction: 'horizontal',
      },
      [breakpointSizes.large]: {
        slidesPerView: 4,
        direction: 'vertical',
      },
      [breakpointSizes.xlarge]: {
        slidesPerView: 5,
        direction: 'vertical',
      },
    },
  };

  const popupSliderOptions = {
    modules: [Navigation, Pagination],
    navigation: true,
    pagination: {
      clickable: true,
    },
  };

  return (
    mainImages.length > 0 && (
      <StyledProductGallery {...props}>
        <StyledInner>
          <StyledMainImages>
            <Slider {...mainSliderOptions}>
              {mainImages.map(
                (
                  {
                    preview: {
                      image: { gatsbyImageData, altText },
                    },
                  },
                  i
                ) => (
                  <StyledMainImage key={i} onClick={() => setShowPopup(true)}>
                    <GatsbyImage
                      image={gatsbyImageData}
                      alt={altText || `${title} Image ${i + 1}`}
                    />
                  </StyledMainImage>
                )
              )}
            </Slider>
            {isOnSale(variants) && <StyledLabel>Sale</StyledLabel>}
          </StyledMainImages>
          {navImages.length > 0 && (
            <StyledNavImages {...navSliderOptions}>
              {navImages.map(
                (
                  {
                    preview: {
                      image: { gatsbyImageData, altText },
                    },
                  },
                  i
                ) => (
                  <StyledNavImage
                    key={i}
                    image={gatsbyImageData}
                    alt={altText || `${title} Image ${i + 1}`}
                  />
                )
              )}
            </StyledNavImages>
          )}
        </StyledInner>
        {popupImages.length > 0 && showPopup && (
          <StyledPopupImages
            onClick={e => e.target === e.currentTarget && setShowPopup(false)}
          >
            <StyledPopupImagesSlider {...popupSliderOptions}>
              {popupImages.map(
                (
                  {
                    preview: {
                      image: { gatsbyImageData, altText },
                    },
                  },
                  i
                ) => (
                  <StyledPopupImage
                    key={i}
                    image={gatsbyImageData}
                    alt={altText || `${title} Image ${i + 1}`}
                  />
                )
              )}
            </StyledPopupImagesSlider>
          </StyledPopupImages>
        )}
      </StyledProductGallery>
    )
  );
};

export default ProductGallery;
